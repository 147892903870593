import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import TableToExcel from "./helpers/Excel";
import { getFormattedDate } from "./helpers/functions";
import { useSearchParams } from 'react-router-dom'

const Teachersdata = () => {
    const [searchParams] = useSearchParams();
    const semel = searchParams.get('semel') || 0;
    const search = useRef(null);
    const [display, setDisplay] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        // Send 'semel' as 'val' in the axios POST request
        axios
            .post("api/teacherdatabymossad", { val: semel })
            .then((res) => {
                console.log(res.data.rows)
                setData(res.data.rows);
                setDisplay(res.data.rows);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    // Function to extract specific columns from the data
    const extractSpecificColumns = (data) => {
        return data.map((row) => {
            const { identifier, first_name, last_name, phone, email, math_cert, math_teach, tech_cert, tech_teach, english_cert, english_teach } = row;
            return {
               "מזהה": identifier,
                "שם": first_name,
                "משפחה": last_name,
                "טל": phone,
                "מייל": email,
            };
        });
    };

    let specificColumnsData = extractSpecificColumns(data);

    return (
        <>
            <Container className="text-center">
                <h3 className="pt-2">נתוני מורים</h3>
                <hr />
                <Row className="mb-1">
                <Col xs={0} md={4} lg={3} xl={7} xxl={7} ></Col>
                    <Col xs={12} md={4} lg={6} xl={2} xxl={2} >
                        <TableToExcel data={specificColumnsData} fileName={`data_${getFormattedDate()}`} />
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={3} xxl={3}>
                        <Form.Control
                            ref={search}
                            type="text"
                            placeholder="חיפוש"
                            onChange={(e) =>
                                setDisplay(
                                    data.filter((i) =>
                                        `${i.first_name} ${i.last_name}${i.phone}${i.phone2}${i.mail}`.includes(
                                            e.target.value
                                        )
                                    )
                                )
                            }
                        />
                    </Col>
                </Row>
                <Table striped bordered hover className="border-3 border-dark">
                    <thead className="table-sticky1">
                        <tr>
                            <th># עובד</th>
                            <th>שם</th>
                            <th>טלפון</th>
                            <th>מייל</th>
                        </tr>
                    </thead>
                    <tbody>
                        {display.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td>{row.identifier}</td>
                                    <td className="text-end">
                                        {row.first_name} {row.last_name}
                                    </td>
                                    <td>{row.phone}</td>
                                    <td>
                                        {row.mail}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

            </Container>
        </>
    );
}

export default Teachersdata;
