import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import {
  Breadcrumb,
  Button,
  Container,
  Form,
  Modal,
  Overlay,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { dateDisplay, getFormattedDate } from "../helpers/functions";
import TableToExcel from "../helpers/Excel";

function Confirmation() {
  const search = useRef(null);
  const [display, setDisplay] = useState([]);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showInitialPopup, setShowInitialPopup] = useState(true); // New state for initial popup

  // Email validation
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Phone validation (9-12 digits, optional +)
  const isValidPhone = (phone) => /^\+?\d{9,12}$/.test(phone);

  useEffect(() => {
    axios
      .post("api/teacherdata")
      .then((res) => {
        const rows = res.data.rows;
        setData(rows);
        setDisplay(rows);

        // Validate initial data and mark invalid fields
        rows.forEach((row) => {
          const phoneInput = document.querySelector(`input[data-row-id="${row.id}"][data-type="phone"]`);
          const emailInput = document.querySelector(`input[data-row-id="${row.id}"][data-type="email"]`);

          if (phoneInput && row.phone && !isValidPhone(row.phone)) {
            phoneInput.classList.add("is-invalid");
          }
          if (emailInput && row.mail && !isValidEmail(row.mail)) {
            emailInput.classList.add("is-invalid");
          }
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleBlur = (type, value, rowId, inputElement) => {
    const isValid = type === 1 ? isValidPhone(value) : isValidEmail(value);
    const originalRow = display.find((row) => row.id === rowId);

    if (type === 2 && originalRow.mail && isValidEmail(originalRow.mail) && !value) {
      alert("אין אפשרות למחוק כתובת מייל תקינה - אנא הכנס מייל תקין");
      inputElement.value = originalRow.mail;
      return;
    }

    if (value && !isValid) {
      inputElement.classList.add("is-invalid");
    } else {
      inputElement.classList.remove("is-invalid");
      handleChange(type, value, rowId, inputElement);
    }
  };

  const handleChange = (placement, val, id, inputElement) => {
    const finalVal = placement === 2 && val === "" ? null : val;
    const data = { val: finalVal, id, placement };
    console.log("Updating data:", data);
    axios
      .post("api/updateteacherdata", data)
      .then((res) => {
        setData(res.data.rows);
        setDisplay(res.data.rows);
        if (placement === 9) {
          console.log(`Confirmed row with ID: ${id}`);
        }
      })
      .catch((error) => {
        console.error("Error updating teacher data:", error);
        if (error.response?.status === 409) {
          inputElement.classList.add("is-invalid");
          alert(`${placement == 1 ? "מספר הטלפון":"כתובת המייל"} כבר קיימת עבור משתמש במוסד זה. נא להחליף`);
        } else {
          alert("שגיאה בעדכון הנתונים: " + (error.response?.data?.error || error.message));
        }
      });
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleConfirm = () => {
    setShow(false);
    axios
      .post("api/updateteacherdatafinished")
      .then((res) => {
        if (res.data.ok === "updated") {
          alert("המידע נשמר בהצלחה");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCloseInitialPopup = () => setShowInitialPopup(false); // Handler to close initial popup

  const allConfirmed = display.every((row) => row.confirm === 1);

  return (
    <>
      <Container className="text-center">
        <h3 className="pt-2">נתוני מורים</h3>
        <div>
          <p>
            הנכם מתבקשים לעדכן את המיילים והטלפונים של עובדי ההוראה, בדקו שהמיילים נכונים, עדכניים ואינם כפולים
          </p>
        </div>
        <hr />
        <Row className="mb-1">
          <Col xs={0} md={0} lg={0} xl={0} xxl={0} className="p-0"></Col>
          <Col xs={12} md={4} lg={3} xl={2} xxl={2}>
            <Button variant="primary" onClick={handleShow} disabled={!allConfirmed}>
              אישור סיום הזנה
            </Button>
          </Col>
          <Col xs={12} md={4} lg={3} xl={2} xxl={2}>
            <Form.Control
              ref={search}
              type="text"
              placeholder="חיפוש"
              onChange={(e) =>
                setDisplay(
                  data.filter((i) =>
                    `${i.first_name} ${i.last_name}${i.phone}${i.phone2}${i.mail}`.includes(
                      e.target.value
                    )
                  )
                )
              }
            />
          </Col>
        </Row>
        <Table striped bordered hover className="border-3 border-dark">
          <thead className="table-sticky1">
            <tr>
              <th># עובד</th>
              <th>שם</th>
              <th>טלפון</th>
              <th>מייל</th>
              <th>פעולה</th>
            </tr>
          </thead>
          <tbody>
            {display.map((row, index) => {
              const isEmailValid = row.mail || isValidEmail(row.mail);
              return (
                <tr key={index}>
                  <td>{row.identifier}</td>
                  <td className="text-end">
                    {row.first_name} {row.last_name}
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={row.phone}
                        data-row-id={row.id}
                        data-type="phone"
                        onBlur={(e) =>
                          handleBlur(1, e.target.value, row.id, e.target)
                        }
                        onFocus={(e) => e.target.classList.remove("is-invalid")}
                        placeholder="הכנס טלפון"
                      />
                      <div className="invalid-feedback">
                        אנא הכנס מספר טלפון תקין (9-12 ספרות)
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        defaultValue={row.mail}
                        data-row-id={row.id}
                        data-type="email"
                        onBlur={(e) =>
                          handleBlur(2, e.target.value, row.id, e.target)
                        }
                        onFocus={(e) => e.target.classList.remove("is-invalid")}
                        placeholder="הכנס מייל"
                      />
                      <div className="invalid-feedback">
                        אנא הכנס כתובת מייל תקינה
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="form-group d-flex justify-content-center gap-3">
                      <button
                        className={`btn ${row.confirm === 1 ? "btn-success" : "btn-outline-success"}`}
                        onClick={() => handleChange(9, 1, row.id)}
                        disabled={!isEmailValid}
                      >
                        <span className={row.confirm === 1 ? "text-white" : ""}>
                          {row.confirm === 1 ? "אושר" : "לאשר"}
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        {/* Initial Popup Modal */}
        <Modal
          show={showInitialPopup}
          onHide={handleCloseInitialPopup}
          size="lg"
          centered
          dir="rtl"
        >
          <Modal.Header closeButton>
            <Modal.Title>עדכון נתונים</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-unstyled">
              <li>
                * נא לעדכן את פרטי המיילים והטלפונים של המורים והמורות בבית ספרכם.
              </li>
              <li>
                * ⁠יש לעבור על הפרטים שכבר מופיעים, לאמת אותם מול העובד ולאשר את נכונותם.
              </li>
              <li>
                * ⁠לעובדים שחסרים פרטים, יש לבקש מהם את הפרטים החסרים, להזין ולאשר אותם.
              </li>
              <li>
                * שימו לב!! כי לא ניתן להזין את אותה כתובת מייל או אותו מספר טלפון ליותר מעובד אחד.
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseInitialPopup}>
              הבנתי
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Confirmation Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          size="sm"
          centered
          dir="rtl"
        >
          <Modal.Header>
            <Modal.Title>מעיין החינוך התורני</Modal.Title>
          </Modal.Header>
          <Modal.Body>בטוח שסיימת לעבור על כלל המורים במוסד?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              ביטול
            </Button>
            <Button variant="danger" onClick={handleConfirm}>
              אישור
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default Confirmation;